import React from 'react';

import styles from './newsAuthors.module.scss';
import { FaUser } from 'react-icons/fa';

interface NewsAuthorsProps {
  authors: string[];
}

export const NewsAuthors: React.FC<NewsAuthorsProps> = ({ authors }) => {
  if (!authors) return null;

  return (
    <div className={styles['authors']}>
      <FaUser className={styles.news__authors__icon} /> Auteur:{' '}
      {authors.map((author: string, index: number) => (
        <span key={index} className={styles['authors__item']}>
          {author}
        </span>
      ))}
    </div>
  );
};
