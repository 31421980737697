import React from 'react';

import styles from './newsTags.module.scss';
import { FaTags } from 'react-icons/fa';

interface NewsTagsProps {
  tags: string[];
}

export const NewsTags: React.FC<NewsTagsProps> = ({ tags }) => {
  if (!tags) return null;

  return (
    <div className="tags">
      <FaTags className={styles['tags__icon']} />

      {tags.map((tag: string, index: number) => (
        <span key={index} className={styles['tag']}>
          {tag}
        </span>
      ))}
    </div>
  );
};
