import React from 'react';

import styles from './newsPostdate.module.scss';
import { FaCalendarDay } from 'react-icons/fa';

interface NewsPostdateProps {
  postdate: Date;
}

export const NewsPostDate: React.FC<NewsPostdateProps> = ({ postdate }) => {
  if (!postdate) return null;

  return (
    <div className={styles['newsitem__postdate']}>
      <FaCalendarDay className={styles['newsitem__postdate__icon']} /> {postdate}
    </div>
  );
};
