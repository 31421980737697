import React from 'react';
import { graphql, PageProps, Link } from 'gatsby';
import Img from 'gatsby-image';

import { Layout } from '../../components/shared/Layout';
import { SEO } from '../../components/shared/seo';
import Share from '../../components/shared/Share/share';
import { ImageSharpFluid } from '../../helpers/definitions';

import styles from './newsTemplate.module.scss';
import { NewsPostDate } from '../../components/news/NewsPostdate';
import { NewsAuthors } from '../../components/news/NewsAuthors';
import { NewsTags } from '../../components/news/NewsTags';

interface DataProps {
  site: {
    siteMetadata: {
      url: string;
      twitterHandle: string;
    };
  };
  news: {
    frontmatter: {
      title: string;
      subtitle: string;
      tags: string[];
      authors: string[];
      post_date: Date;
      cover: {
        childImageSharp: {
          fluid: ImageSharpFluid;
        };
      };
    };
    html: string;
  };
}

const NewsTemplate: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { news, site } = data;
  const { frontmatter, html } = news;
  const { post_date, title, subtitle, tags, authors } = frontmatter;

  const sc = {
    twitterHandle: site.siteMetadata.twitterHandle,
    config: { url: site.siteMetadata.url, title: frontmatter.title },
  };

  return (
    <Layout padded={true}>
      <SEO title={frontmatter.title} />
      <div className="container">
        <header className={styles['news-header']}>
          <div className="row">
            <div className="col-xs-6">
              <Link to="/nieuws" className={styles['backlink']}>
                &larr; Bekijk het volledige nieuws overzicht
              </Link>
            </div>
            <div className="col-xs-6" style={{ textAlign: 'right' }}>
              <Share socialConfig={sc} />
            </div>
          </div>

          <h1 className={styles['news-title']}>{title}</h1>

          {frontmatter.cover && <Img fluid={frontmatter.cover.childImageSharp.fluid} />}
        </header>

        <section className={styles['news-main']}>
          <div className="row">
            <div className="col-xs-12 col-md-8">
              {frontmatter && <h2 className={styles['news-subtitle']}>{subtitle}</h2>}
              <div className={styles['news-content']} dangerouslySetInnerHTML={{ __html: html }} />
            </div>
            <div className="col-xs-12 col-md-4">
              <NewsPostDate postdate={post_date} />

              <NewsAuthors authors={authors} />

              <NewsTags tags={tags} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        url
        twitterHandle
      }
    }
    news: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        post_date(formatString: "DD MMMM YYYY")
        slug
        title
        subtitle
        tags
        authors
        cover {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default NewsTemplate;
